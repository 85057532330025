import _readableStream from "readable-stream";
import _util from "util";
import _xtend from "xtend";
import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var process = _process;
var Transform = _readableStream.Transform,
    inherits = _util.inherits,
    xtend = _xtend;

function DestroyableTransform(opts) {
  Transform.call(this || _global, opts);
  (this || _global)._destroyed = false;
}

inherits(DestroyableTransform, Transform);

DestroyableTransform.prototype.destroy = function (err) {
  if ((this || _global)._destroyed) return;
  (this || _global)._destroyed = true;
  var self = this || _global;
  process.nextTick(function () {
    if (err) self.emit("error", err);
    self.emit("close");
  });
}; // a noop _transform function


function noop(chunk, enc, callback) {
  callback(null, chunk);
} // create a new export function, used by both the main export and
// the .ctor export, contains common logic for dealing with arguments


function through2(construct) {
  return function (options, transform, flush) {
    if (typeof options == "function") {
      flush = transform;
      transform = options;
      options = {};
    }

    if (typeof transform != "function") transform = noop;
    if (typeof flush != "function") flush = null;
    return construct(options, transform, flush);
  };
} // main export, just make me a transform stream!


exports = through2(function (options, transform, flush) {
  var t2 = new DestroyableTransform(options);
  t2._transform = transform;
  if (flush) t2._flush = flush;
  return t2;
}); // make me a reusable prototype that I can `new`, or implicitly `new`
// with a constructor call

exports.ctor = through2(function (options, transform, flush) {
  function Through2(override) {
    if (!((this || _global) instanceof Through2)) return new Through2(override);
    (this || _global).options = xtend(options, override);
    DestroyableTransform.call(this || _global, (this || _global).options);
  }

  inherits(Through2, DestroyableTransform);
  Through2.prototype._transform = transform;
  if (flush) Through2.prototype._flush = flush;
  return Through2;
});
exports.obj = through2(function (options, transform, flush) {
  var t2 = new DestroyableTransform(xtend({
    objectMode: true,
    highWaterMark: 16
  }, options));
  t2._transform = transform;
  if (flush) t2._flush = flush;
  return t2;
});
export default exports;
export const ctor = exports.ctor,
      obj = exports.obj;